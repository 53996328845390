import Vue from 'vue'
import { baseUrl } from '../endpoints'

const timeout = 20000

export default {
  interestQuestions () {
    return Vue.axios.get(baseUrl + 'admin/interests/questions',
      {
        timeout: timeout,
        //   headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        data: null
      }
    )
  },
  addInterestQuestion (data) {
    return Vue.axios.post(baseUrl + 'admin/interests/add', data, {
      timeout: timeout
    })
  },
  deleteInterestQuestion (id) {
    return Vue.axios.delete(baseUrl + `admin/interests/${id}/delete`, {
      timeout: timeout
    })
  },
  updateInterestQuestion (data, id) {
    return Vue.axios.post(baseUrl + `admin/interests/${id}/update`, data, {
      timeout: timeout
    })
  },
  preferenceQuestions () {
    return Vue.axios.get(baseUrl + 'admin/preferences/questions',
      {
        timeout: timeout,
        //   headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        data: null
      }
    )
  },
  addPreferenceQuestion (data) {
    return Vue.axios.post(baseUrl + 'admin/preferences/add', data, {
      timeout: timeout
    })
  },
  deletePreferenceQuestion (id) {
    return Vue.axios.delete(baseUrl + `admin/preferences/${id}/delete`, {
      timeout: timeout
    })
  },
  updatePreferenceQuestion (data, id) {
    return Vue.axios.post(baseUrl + `admin/preferences/${id}/update`, data, {
      timeout: timeout
    })
  },
  allUsers (page) {
    return Vue.axios.get(baseUrl + 'admin/users',
      {
        timeout: timeout,
        //   headers: { Accept: 'application/json', 'Content-type': 'application/json' },
        data: null
      }
    )
  },
  deleteUser (id) {
    return Vue.axios.delete(baseUrl + `admin/users/${id}`, {
      timeout: timeout
    })
  },
  restoreUser (id) {
    return Vue.axios.get(baseUrl + `admin/users/restore/${id}`, {
      timeout: timeout
    })
  },
  editUser (data, id) {
    return Vue.axios.get(baseUrl + `admin/users/${id}/edit?role_id=` + data.role_id, {
      timeout: timeout
    })
  },
  searchName (data) {
    return Vue.axios.get(baseUrl + 'admin/searchName?name=' + data, {
      timeout: timeout
    })
  },
  searchRole (data) {
    return Vue.axios.get(baseUrl + 'admin/searchRole?role_id=' + data, {
      timeout: timeout
    })
  },
  sortByName () {
    return Vue.axios.get(baseUrl + 'admin/sortName', {
      timeout: timeout
    })
  },
  sortByRole () {
    return Vue.axios.get(baseUrl + 'admin/sortRole', {
      timeout: timeout
    })
  },

  // agencies
  getActiveAgencies () {
    return Vue.axios.get(baseUrl + 'admin/active-agencies', { timeout: timeout })
  },

  getInActiveAgencies () {
    return Vue.axios.get(baseUrl + 'admin/inactive-agencies', { timeout: timeout })
  },

  getPendingAgencies () {
    return Vue.axios.get(baseUrl + 'admin/pending-agencies', { timeout: timeout })
  },

  updateAgencyStatus (id, body) {
    return Vue.axios.patch(baseUrl + 'admin/agencies/' + id, body, { timeout: timeout })
  },

  agencyDetails (id) {
    return Vue.axios.get(baseUrl + 'admin/agencies/' + id, { timeout: timeout })
  },

  tripTags () {
    return Vue.axios.get(baseUrl + 'admin/tags', { timeout: timeout })
  },

  addTripTag (body) {
    return Vue.axios.post(baseUrl + 'admin/tags', body, { timeout: timeout })
  },

  updateTripTag (id, body) {
    return Vue.axios.put(baseUrl + 'admin/tags/' + id, body, { timeout: timeout })
  },

  deleteTripTag (id) {
    return Vue.axios.delete(baseUrl + 'admin/tags/' + id, { timeout: timeout })
  },

  linkTagWithQuestion (id, body) {
    return Vue.axios.post(baseUrl + 'admin/tags/' + id + '/assign-to-answer', body, { timeout: timeout })
  },

  linkTagWithProfile (id, body) {
    return Vue.axios.post(baseUrl + 'admin/tags/' + id + '/assign-to-profile', body, { timeout: timeout })
  },

  getLinkedTags (link) {
    return Vue.axios.get(baseUrl + 'admin/tags/' + link, { timeout: timeout })
  }
}
